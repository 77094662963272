<template>
    <div class="wrapper flex flex-column">
        <Header class="live-header" @search="handleSearchEvent" :navList="navList" navCurrentId="1" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container filter-wrapper" v-if="subjectList.length">
                <filter-tab title="学科" :selected="subjectIndex" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>
            <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1 || $route.query.search_name" class="filter-condition mt20 active">
                <div class="container flex y-center x-left">
                    <div class="search-text">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="$route.query.search_name">{{ $route.query.search_name }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"  style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div class="live-content container flex mt20">
                <div class="live-list flex-item">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="mh" v-if="liveList.length">
                                <!-- 直播推荐模块 -->
                                <div class="flex live-banner">
                                    <div class="recommend-first" @click="liveJumpDetail(recommendFirst.live_id)">
                                        <img style="object-fit:cover;" class="rf-image" :src="recommendFirst.live_banner | urlFilter(360)" alt="直播标题">
                                        <div v-if="recommendFirst.title" :title="recommendFirst.title" class="row-1 colorfff rf-title fs12 pl10 pr10 t-l">{{recommendFirst.title}}</div>
                                        <div class="rf-info flex y-center">
                                            <div v-if="recommendFirst.status == 0" class="t-l">
                                                <div class="rf-info-text fs12 colorfff">距离直播还有</div>
                                                <div class="rf-info-time">
                                                    <template v-if="countdownTime.D > 0">
                                                        <b>{{countdownTime.D}}</b>
                                                        <sub>天</sub>
                                                    </template>
                                                <b>{{countdownTime.H}}</b>
                                                <sub>时</sub>
                                                <b>{{countdownTime.M}}</b>
                                                <sub>分</sub>
                                                <b>{{countdownTime.S}}</b>
                                                <sub>秒</sub>
                                            </div>
                                        </div>
                                        <div v-else class="fs16 colorfff flex-item t-l pr30 row-2">{{recommendFirst.title}}</div>
                                        <!-- 预约直播 -->
                                        <div v-if="recommendFirst.status == 0" @click.stop="appointLive">
                                            <div class="rf-info-btn live-start" v-if='recommendFirst.applet_remind == 0'>预约直播</div>
                                            <div class="rf-info-btn live-appointed" v-if='recommendFirst.applet_remind == 1'>已预约</div>
                                        </div>
                                        <!-- 直播中 -->
                                        <div v-else-if="recommendFirst.status == 1" class="rf-info-btn living live-status"><b></b><b></b><b></b><b></b>直播中</div>
                                        <!-- 观看回放 -->
                                        <div v-else class="rf-info-btn lived">观看回放</div>
                                    </div>
                                </div>
                                <div class="recommend-list">
                                    <common-title title="推荐直播" :isMore="false"></common-title>
                                    <div v-for="(item,index) in recommendList" :key="index" class="recommend-item t-l" @click="liveJumpDetail(item.live_id)">
                                        <div :title="item.title" class="fs16 color333 row-1" v-html="item.title"></div>
                                        <div class="fs14 color999"><i class="med med-31shijian"></i>{{item.start_time.substr(0,10)}}</div>
                                    </div>
                                </div>
                            </div>

                            <!-- 直播列表模块 -->
                            <div class="flex flex-wrap x-left mt10 live-item-box">
                                <template v-for="(item,index) in liveList">
                                    <live-item @click="liveJumpDetail(item.live_id)" :key="index" class="mt20" width="230px" :item="item">
                                    </live-item>
                                </template>
                            </div>
                        </div>
                        <a-empty v-if="!liveList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt30" v-if="liveTotal > 0">
                        <a-pagination :total="liveTotal" v-model:current="params.page" :page-size="params.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                    </div>
                </div>
                <div class="live-right">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import layoutRight from '@/components/layout/layout-right';
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import filterTab from '@/components/filter-tab';
import acrossMediaItem from '@/components/across-media-item';
import liveItem from '@/components/live-item.vue';
import { liveLogo, mediaNavList } from '@/config/const'
import { dateFormat, UTCToLocalTimeString } from '@/utils/dateFormat.js';
import { jsonFormat } from '@/utils/jsonFormat';
import { mapState } from 'vuex'
import { liveJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store';
let layoutRightConfig = {
    video: {
        show: true, // 是否显示
        methods: 'get',
        title: '最新回放', // 标题
        data: {
            page: 1,
            PageSize: 4,
            subject_ids: storage.get('subjectIds') || ''
        }, // 参数条件 
    },
    meeting: {
        show: true, // 是否显示
        title: '本月会议', // 标题
        methods: 'get',
        moreLink: '/meeting-list?year=' + (new Date()).getFullYear() + '&month=' + ((new Date()).getMonth() + 1),
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 6,
            is_now_year_meeting: 1,
            subject_ids: storage.get('subjectIds') || ''
        }, // 参数条件
    },
}
export default {
    name: "LiveList",
    components: {
        commonTitle,
        Header,
        filterTab,
        liveItem,
        acrossMediaItem,
        layoutRight,
        Footer
    },
    data() {
        return {
            logoUrl: liveLogo,
            navList: mediaNavList, //顶部菜单
            // 学科筛选
            subjectIndex: -1,
            subjectList: [],
            liveTotal: 0, //直播列表总数
            // 直播列表请求参数
            params: {
                page: 1,
                limit: 6,
                tj_token: storage.get('tid'),
            },
            countdownTime: {
                D: '0',
                H: '00',
                M: '00',
                S: '00'
            },
            // 直播特别推荐
            recommendFirst: {},
            // 直播推荐
            recommendList: [],
            // 直播列表
            liveList: [],
            layoutRightConfig: {},
            loading: true,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        liveJumpDetail,
        // 清除事件
        handleClearEvent() {
            this.subjectIndex = -1
            if (this.$route.query.search_name) {
                this.$router.replace({
                    path: '/live-list'
                })
            }
            this.handleFilterEvent();
        },
        // 获取推荐直播
        getCommandLiveList(subject_ids) {
            let data = {
                page: 1,
                limit: 5,
                subject_ids,
                tj_token: storage.get('tid'),
            }
            this.request.get('GetRecommendedLive', data).then(res => {
                this.recommendFirst = res.data[0]
                this.recommendList = res.data.slice(1, 5)
                this.setLiveStatus();
                !this.recommendFirst.timer && (this.recommendFirst.timer = setInterval(this.setLiveStatus, 1000));
            })
        },
        // 获取全部学科
        getSubject() {
            return this.request.get('NewSubject')
        },
        handleSearchEvent() {
            this.params.page = 1
            this.getLiveList()
        },
        // 获取直播列表
        getLiveList() {
            let data = { ...this.params };
            let { search_name } = this.$route.query;
            search_name && (data['title'] = search_name);
            this.request.get('GetLiveList', data).then(res => {
                let liveObj = {
                    start_date: 'start_time',
                    end_date: 'end_time',
                    img: 'live_banner'
                }
                this.liveTotal = Number(res.data.count)
                this.liveList = jsonFormat(res.data.list, liveObj)
            })
        },
        // 学科切换
        handleFilterEvent(key, e) {
            this.params.page = 1;
            key && (this[key] = e);
            let { subject_id } = this.$route.query;
            let subject_ids = this.subjectIndex == -1 ? subject_id : this.subjectList[this.subjectIndex].subject_ids;
   
            if(subject_ids){
                this.params.subject_ids = subject_ids
            }else{
                delete this.params.subject_ids;
            }
            // 更新右侧内容
            layoutRightConfig.video.data.subject_ids = subject_ids
            layoutRightConfig.meeting.data.subject_ids = subject_ids
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

            this.getCommandLiveList(subject_ids);
            this.getLiveList()
        },
        // 判断特别推荐的直播状态
        setLiveStatus() {
            let nowTime = UTCToLocalTimeString(new Date()).getTime();
            let startTime = this.recommendFirst.start_time.replace(/-/g, '/');
            let endTime = this.recommendFirst.end_time.replace(/-/g, '/');
            endTime = endTime.slice(0, 10) + ' 23:59:59'
            startTime = +(new Date(startTime));
            endTime = +(new Date(endTime));
            if (startTime > nowTime) {
                // 未开始
                this.$set(this.recommendFirst, 'status', 0);
                // 获取倒计时
                let countdownTime = dateFormat(startTime - nowTime);
                let countdownTimeArr = countdownTime.split(',')
                let D = countdownTimeArr[0];
                let timeArr = countdownTimeArr[1].split(':');
                let H = timeArr[0];
                let M = timeArr[1];
                let S = timeArr[2];
                this.countdownTime = { D, H, M, S }
            } else if (nowTime > startTime && endTime > nowTime && this.recommendFirst.is_live_in == '1') {
                // 直播中
                this.$set(this.recommendFirst, 'status', 1);
                clearInterval(this.recommendFirst.timer);
            } else {
                // 已结束
                this.$set(this.recommendFirst, 'status', 2);
                clearInterval(this.recommendFirst.timer);
            }
        },
        pageInit() {
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            let requestArr = [
                this.getSubject(),
            ]
            Promise.all(requestArr).then(res => {
                // 学科
                let subjectList = [];
                res[0].data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })

                this.subjectList = subjectList;

                // 默认执行一次
                this.handleFilterEvent();
                
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0, 0);
            this.params.page = page
            this.getLiveList()
        },
        // 预约直播
        appointLive() {
            let { live_id } = this.recommendFirst
            this.request.post('AppletAppointment', {
                pages_url: "/live-pages/live-play/live-play?id=" + live_id,
                type: 1,
                type_id: live_id,
                tj_token: storage.get('tid')
            }).then(res => {
                this.recommendFirst.applet_remind = 1
            })
        }
    },
    mounted() {
        this.pageInit();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    background: #fff;
    overflow: hidden;
    height: 8px;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.live-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.live-list {
    margin-right: 30px;
    .mh {
        min-height: 500px;
    }
}

// 直播推荐
.recommend-first {
    width: 50%;
    position: relative;
    cursor: pointer;
    .rf-title {
        position: absolute;
        bottom: 67px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 35px;
        line-height: 35px;
        box-sizing: border-box;
    }
    .rf-image {
        width: 100%;
        height: 208px;
    }
    .rf-info {
        height: 67px;
        padding: 10px 20px;
        box-sizing: border-box;
        background: #1c2769;
        .rf-info-time {
            margin-top: 5px;
            b {
                font-size: 18px;
                color: #ffa45d;
                font-weight: bold;
                background: #2f3c8a;
                border-radius: 5px;
                padding: 3px;
            }
            sub {
                font-size: 12px;
                color: #fff;
                margin: 0 5px;
                opacity: 0.7;
            }
        }
        .rf-info-btn {
            padding: 6px 15px;
            border-radius: 35px;
            line-height: 20px;
            height: 32px;
            font-size: 12px;
            color: #fff;
        }
        .live-start {
            background: linear-gradient(to right, #f5bf58, #f2873b);
        }
        .living {
            background: linear-gradient(to right, #5f96e6, #1e51c9);
        }
        .lived {
            background: linear-gradient(to right, rgb(87, 188, 211), #1eabc9);
        }
        .live-appointed {
            background: #cccccc;
        }
    }
}

.recommend-list {
    width: calc(50% - 20px);
    margin-left: 20px;
    height: 276px;
    .recommend-item {
        padding-bottom: 4px;
        margin-top: 7px;
        display: block;
        border-bottom: 1px dashed #d8d8d8;
        cursor: pointer;
        &:last-child {
            padding-bottom: 0;
            border: 0;
        }
        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
}

.live-item {
    margin-right: 20px;
    &:nth-child(3n) {
        margin-right: 0;
    }
}

.live-right {
    width: 400px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        .filter-condition.active{
            margin-top:10px;
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .filter-wrapper {
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            background: #f2f4fa;
            z-index: 9;
        }
        .live-content {
            display: block;
            margin-top:10px;
            padding: 0px 10px 0;
            .live-list {
                width: 100%;
                .live-banner {
                    display: block;
                    padding-top: 15px;
                    .recommend-first {
                        width: 100%; // width: calc(100% + 20px);
                        // margin-left: -10px;
                        .rf-image {
                            height: auto;
                        }
                    }
                    .recommend-list {
                        width: 100%;
                        height: auto;
                        margin-top: 20px;
                        margin-left: 0;
                    }
                }
            }
            .live-right {
                width: 100%;
                margin-top: 60px;
            }
        }
    }
    .live-item-box {
        justify-content: space-between;
    }
    .live-item {
        margin-right: 0;
    }
}

@import url("~@/assets/css/live-icon.less");
</style>
